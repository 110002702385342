// Small tablets and large smartphones (landscape view)
$screen-sm-max: 576px;

// Small tablets (portrait view)
$screen-md-max: 768px;

// Tablets and small desktops
$screen-lg-max: 992px;

// Large tablets and desktops
$screen-xl-max: 1200px;

// Small devices
@mixin sm {
  @media (max-width: #{$screen-sm-max}) {
    @content;
  }
}

// Medium devices
@mixin md {
  @media (max-width: #{$screen-md-max}) {
    @content;
  }
}

// Large devices
@mixin lg {
  @media (max-width: #{$screen-lg-max}) {
    @content;
  }
}

// Extra large devices
@mixin xl {
  @media (max-width: #{$screen-xl-max}) {
    @content;
  }
}

@mixin shadow {
  box-shadow: 0 6px 6px -6px rgba(0, 0, 0, 0.3);
}

@mixin bg-gradient() {
  background: linear-gradient(
    135deg,
    rgba(4, 124, 230, 1) 0%,
    rgba(4, 124, 230, 1) 15%,
    rgba(0, 242, 96, 1) 100%
  );
}

@mixin btn-block {
  display: block;
  width: 100%;
}